.noticias_hover > div :hover {
  cursor: pointer;
  background: #1e282e;
}
.textoby {
  color: var(--green-500, #adcab8);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.266px; /* 140% */
}

.typografiaDetalle {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.61px; /* 144% */
}



.clipboard.button-custom {
  background: transparent;
  border: none;
  outline: none;
  background-color: transparent;
  color: initial;
  border: initial;
  padding: initial;
  border-radius: initial;
  cursor: initial;
  /* configuration */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFCABF;
}

.custom-svg-fill {
  color: #FFCABF;
  fill: #FFCABF;
}

.singlenews{
  align-items: center;
  background: #2E3C46;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 30px auto;
  max-width: 800px;
  padding: 24px 40px;
  padding: 10px 20px 20px;
}
.singlenews__top {
  color: #D4D8DB;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  align-content: center;
}
.singlenews__top__right {
  display: flex;
}
.singlenews__top__link{
  width: 50px;
  height: 50px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.singlenews__top__left {
  font-size: 15px;
  line-height: 23px;
  color: #D4D8DB;
  
}
.singlenews__top__left__title{
  text-transform: capitalize;

}
.singlenews__top__save,
.singlenews__top__share{
  fill: white;
}

.singlenews__title{
  color: var(--gray-100, #eceeef);
  font-family: Raleway;
  font-size: 28px;
  line-height: 31px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 10px;
}

.singlenews__figure{
  aspect-ratio: 895 / 420; 
  margin: 0 0 10px;
  width: 100%; 
}
.singlenews__img{
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.singlenews__body {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  color: #D4D8DB;
  font-size: 15px;
  line-height: 23px;
  margin: 0;
}
.singlenews__body p{
  color: #D4D8DB !important;
  font-size: 15px !important;
  line-height: 23px !important;
  white-space: pre-line;
}
.singlenews__body em,
.singlenews__body a{
  color: #D4D8DB !important;
  text-decoration: none;
  background: transparent !important;
}
.singlenews__body .c-ad__placeholder{
  display: none;
}
.singlenews__body img{
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0 0 10px;
}

.singlenews__body h1,
.singlenews__body h2,
.singlenews__body h3,
.singlenews__body h4,
.singlenews__body h5,
.singlenews__body h6{
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 34px !important;
  line-height: 34px !important;
  color: #D4D8DB !important;
  margin-bottom: 20px;
}
.singlenews__body .post-meta,
.singlenews__body .shariff,
.singlenews__body svg {
  display: none !important;
}


/* .singlenews__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #ADCAB8;
} */

.singlenews__top__right {
  display: flex;
}

.singlenews__actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ADCAB8;
  font-weight: 600;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  margin: 6px 0 15px;
}

.clipboard__btn {
  align-items: center;
  background: #0000;
  background-color: initial;
  border: initial;
  border-radius: 0;
  border-radius: initial;
  color: initial;
  color: #ffcabf;
  cursor: auto;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding: initial;
  display: flex;
  gap: 5px;
  font-weight: 600;
}

.clipboard__svg {
  fill: #ffcabf;
}

.singlenews__actions__right {

  display: flex;
  gap: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.singlenews__actions__save {
  color: #ffcabf;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
}

.singlenews__actions__save__svg {
  fill: #ffcabf;
}
.singlenews__actions__text{
  
  margin: 0;
  display: flex;
  gap: 5px;
}
.singlenews__actions__text a{
  color: #ADCAB8;
  text-decoration: none;
}
.singlenews__actions__copy{
  color:#ffcabf;
}
.singlenews__actions__copy svg{
  fill: #ffcabf;
}


.singlenews__footer__text {
  padding: 3px 9px;
  text-align: center;
  background-color: #425563;
  border-radius: 8px;
  color: var(--gray-200, #d4d8db);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  text-decoration: none;
}

a.singlenews__footer__text {
  padding: 3px 9px;
  text-align: center;
  background-color: #425563;
  border-radius: 8px;
  color: var(--gray-200, #d4d8db);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  text-decoration: none;
}

.singlenews__footer {
  display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media (min-width: 768px) {}

@media (min-width: 1024px) {
  .singlenews {  
    gap: 4px;
    margin: 50px auto;
    padding: 24px 40px;
    align-items: baseline;
  }
  .singlenews__title{
    font-size: 35px;
    line-height: 48px;
    margin: 0 0 24px;
  }
  .singlenews__figure{
    padding: 0 15px;
    margin: 0 0 24px;
  }
  .singlenews__body{
    font-size: 17px;
    line-height: 25px;
  }
  .singlenews__actions{
    flex-direction: row;
    margin: 28px 0 49px;
  }
 
  .singlenews__actions__right{
    width: initial;
  }

}

@media (min-width: 1440px) {}
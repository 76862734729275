.suscripciones_page {
	max-width: 1216px;
	margin: 0 auto;
}
.title_suscripciones {
	color: #eceeef;
	font-family: 'Raleway';
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 48px;
	margin: 26px 0 0;
}
.desc_suscripciones {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 17.09px;
	line-height: 24px;
	color: #d4d8db;
	margin: 7px 0 35px;
}
.suscripciones_head {
	border-bottom: 1px solid #425563;
	height: 103px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.suscripciones_head_left {
	display: flex;
}
.suscripciones_head_button {
	align-items: center;
	background: #ff8166;
	border-radius: 20px;
	color: #1a2228;
	display: flex;
	flex-direction: row;
	font-family: 'Barlow';
	font-size: 13.5px;
	font-style: normal;
	font-weight: 600;
	gap: 10px;
	justify-content: center;
	line-height: 19px;
	padding: 10px 16px;
	margin: 0 10px 0 0;
	transition: 0.2s all ease-out;
	min-width: 120px;
}
.suscripciones_head_button:hover {
	background: #df7057;
}

.suscripciones__loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    
    .css-ue6sde{
        min-height: 40px;
    }
}
    
.suscripciones_head_item {
	border-bottom: 3px solid transparent;
	display: flex;
	flex-direction: column;
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	gap: 11px;
	font-size: 15px;
	line-height: 21px;
	padding: 22px 20px 20px;
	text-align: center;
	cursor: pointer;

	line-height: 1;
	padding: 12px 10px 20px;
	max-width: 130px;
}
.suscripciones_head_icon {
	/* height: 30px; */
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: flex-start;
}
.suscripciones_head_item.suscripciones_head_item--active {
	border-bottom: 3px solid #ff5c39;
	font-weight: 600;
}

.suscripciones_empty_title {
	color: #eceeef;
	font-family: 'Raleway';
	font-size: 19.22px;
	font-style: normal;
	font-weight: 600;
	line-height: 27px;
	margin: 0;
	text-align: center;
}

.suscripciones_empty_text {
	color: #bbc2c7;
	display: block;
	font-family: 'Barlow';
	font-size: 17.09px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: 0 auto 10px;
	max-width: 600px;
	text-align: center;
}

.suscripciones_empty {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 19px;
	margin: 140px 0;
	padding: 0 20px;
	width: 100%;
}

.suscripciones_empty_button {
	align-items: center;
	background: #ff8166;
	border-radius: 20px;
	color: #1a2228;
	display: flex;
	flex-direction: row;
	font-family: 'Barlow';
	font-size: 13.5px;
	font-style: normal;
	font-weight: 600;
	gap: 10px;
	justify-content: center;
	line-height: 19px;
	padding: 10px 16px;
	transition: 0.2s all ease-out;
}
.suscripciones_empty_button:hover {
	background: #df7057;
}
.container_suscripciones {
	background-color: var(--gray-900, #2e3c46);
	color: white;
	display: flex;
	flex-wrap: wrap;
	border-radius: 0;
	gap: 0;
	margin: 0;
	flex-direction: column;
	min-height: 480px;
}
.container_packs {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px;
}

.table_packs_overflow {
	overflow: scroll;
	position: relative;
	width: 100%;
}
.table_packs {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 20px 40px;
	min-width: 920px;
}

.table_packs_row {
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.table_packs_col_icon {
	flex: 0 0 50px;
	text-align: center;
}

.table_packs_col_package {
	flex: 0 0 105px;
}

.table_packs_col_total {
	flex: 0 0 130px;
}

.table_packs_col_date {
	flex: 0 0 160px;
}

.table_packs_col_expires {
	flex: 0 0 148px;
}

.table_packs_col_remaining {
	flex: 0 0 130px;
}

.table_packs_col_lkn {
	flex: 0 0 80px;
}

.table_packs_row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	flex-direction: row;
}
.table_packs_row_filled {
	background: #425563;
	border-radius: 8px;
	padding-top: 16px;
	padding-bottom: 16px;
}

.table_packs_col_title {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 700;
	font-size: 15.19px;
	line-height: 24px;
	color: #eceeef;
}

.table_packs_icon_circle {
	align-items: center;
	background: #5e6f7a;
	border-radius: 125px;
	display: flex;
	height: 40px;
	justify-content: center;
	margin: 0 auto;
	width: 40px;
}
.active .table_packs_icon_circle {
	background: #d2ef5a;
}
.pending .table_packs_icon_circle {
	background: #d4d8db;
}
.pending .table_packs_date {
	color: #1a2228;
	background: #f4b400;
	font-weight: 600;
	font-size: 15.19px;
	line-height: 17px;
	border-radius: 6px;
	padding: 1px 8px 2px;
}
.active .table_packs_icon_svg {
	fill: #1a2228;
}
.active .table_packs_remaining {
	background: #d2ef5a;
	color: #1a2228;
}
.table_packs_package {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 700;
	font-size: 15.19px;
	line-height: 27px;
	color: #eceeef;
}
.table_packs_date,
.table_packs_total {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 15.19px;
	line-height: 27px;
	color: #eceeef;
}
.table_packs_expires {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	font-size: 15.19px;
	line-height: 17px;
	color: #425563;
	background: #97a2a9;
	border-radius: 6px;
	padding: 1px 8px 2px;
}
.table_packs_remaining {
	background: #2e3c46;
	border-radius: 6px;
	color: #eceeef;
	font-family: 'Barlow';
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 17px;
	padding: 1px 8px 2px;
}
.table_packs_lkn {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	font-size: 15.19px;
	line-height: 27px;
	color: #ffcabf;
}
.packs_body {
	overflow: scroll;
	max-height: 50vh;
	position: relative;
}
@media (min-width: 768px) {
	.container_suscripciones {
		border-radius: 12px;
		min-height: 580px;
		margin: 0;
	}
	.title_suscripciones {
		font-size: 34.64px;
	}
	.suscripciones_head {
		height: 103px;
	}
	.suscripciones_head_button {
		margin: 0 40px 0 0;
		min-width: inherit;
	}
	.suscripciones_head_item {
		padding: 22px 24px 20px;
		font-size: 15px;
		line-height: 21px;
		max-width: inherit;
	}
	.container_packs {
		padding: 24px 40px;
	}

	.packs_body {
		overflow: inherit;
		max-height: inherit;
		position: relative;
	}
}

.sectionMainContainer {
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  align-items: flex-start;
  margin-bottom: 10%;
}

.sectionText {
  font-family: Raleway;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin-bottom: 25px;
}

.sectionDescription {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #d4d8db;
  margin-bottom: 25px;
  width: 80%;
}

.sectionDescriptionResponsive {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #d4d8db;
  margin-bottom: 25px;
  width: 100%;
}

.sectionDescriptionResponsive {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #d4d8db;
  margin-bottom: 25px;
  width: 100%;
}

.rowMainContainer {
  background-color: #2e3c46;
  border-radius: 8px;
  padding: 20px;
  margin-top: 22px;
  /* width: 600px; */
  width: 100%;
}

.rowMainContainerResponsive {
  background-color: #2e3c46;
  border-radius: 8px;
  padding: 20px;
  margin-top: 22px;
  /* width: 600px; */
  width: 100%;
}

.subitemTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.itemCircleIcon {
  width: 8px;
  height: 8px;
  background-color: #97a2a9;
  border-radius: 100px;
  align-self: center;
  position: absolute;
  top: 8px;
}

.itemTitle {
  font-family: Raleway;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.itemText {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0;
  margin-left: 16px;
}


@media (min-width: 1024px) {  
  
  .sectionMainContainer{
    flex-direction: row;
  }
  .sectionMainContainer-mt {
    padding: 0 25px 0 0;
  }
  .rowMainContainer, 
  .rowMainContainerResponsive{
    padding: 35px;
  }
}
.price-calculator__container-main {
	background: var(--gray-800, #2e3c46);
	border-radius: 0.75em;
	display: flex;
	flex-direction: column;
	height: auto;
	margin-right: auto;
	max-width: 800px;
	width: 100%;
	padding-bottom: 0;
}

.price-calculator__container-title {
	color: #fff;
	font-family: Raleway;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-left: 0px;
	margin-top: 0;
	margin-bottom: 24px;
	display: inline-block;
}

.price-calculator___container {
	display: block;
}

.price-calculator___inner {
	color: #fff;
	font-family: Raleway;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 32px;
}

.price-calculator___sub-inner {
	color: #fff;
	font-family: Raleway;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-left: 3.7em;
}
.price-calculator-btn {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	font-size: 13.5px;
	line-height: 19px;
	color: #eceeef;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0;
	width: 135px;
	height: 54px;
	background: #1a2228;
	border-radius: 4px;
	/* margin-left: 8px; */
}
.popup-replace {
	position: relative;
}
.popup-replace svg {
	position: absolute;
	top: 10px;
	left: 11px;
}

.popup-input-replace {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 12px 8px 35px;
	gap: 10px;
	margin: 0 0 30px;
	height: 38px;
	color: #d4d8db;
	background: #1a2228;
	border-radius: 8px;
	width: 100%;
}
.search-name {
	position: relative;
	margin: 21px 0 0;
}
.search-name-button {
	position: absolute;
	right: 0;
	padding: 13px 12px;
}
.seach-select {
	padding: 5px 4px;
}
.seach-filter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.seach-filter-results {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 15.19px;
	line-height: 24px;
}
.search-name-input {
	height: 50px;
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 15.19px;
	line-height: 21px;
	color: #eceeef;
	width: 100%;
	box-sizing: border-box;
	background: #425563;
	box-shadow: inset 0px -1px 0px #5e6f7a;
	border-radius: 4px 4px 0px 0px;
	padding: 10px 10px 10px 10px;
	margin-bottom: 30px;
}
.search-name-input::placeholder {
	color: #bbc2c7;
}

.list-calculos {
	min-height: 130px;
	margin: 23px 0;
	gap: 5px;
	display: flex;
	flex-direction: column;
	max-height: 250px;
	min-height: 250px;
	overflow: scroll;
}
.list-calculos-item {
	cursor: pointer;
	display: flex;

	flex-wrap: nowrap;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	padding: 5px 12px 8px;
	border-radius: 8px;
}
.list-calculos-item.selected {
	background: #1a2228;
}

.list-calculos-check {
	margin-left: auto; /* Alinea el ícono de check a la derecha */
}
.list-calculos-text {
	margin: 3px 0 0;
}

.price-calculator-first {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	align-items: center;
}

.price-calculator-first .price-calculator__textbox-general {
	flex: 1;
	order: 1;
}

.price-calculator-first .price-calculator-ml-lg {
	order: 1;
	flex-shrink: 0;
}

.price-calculator-first .price-calculator-btn {
	order: 2;
	flex-basis: 100%;
	width: 100%;
}
.popup-button.disabled {
	background: #425563;
	cursor: not-allowed;
}

.price-calculator__textbox-general {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	margin-bottom: 0.1em;
	padding-left: 0.5em;
	width: 693px;
	color: #eceeef;
}

.price-calculator__textbox-general-col1 {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	margin-bottom: 0.1em;
	padding-left: 0.5em;
	width: 345px;
	color: #eceeef;
}

.price-calculator__textbox-double-general-col {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	padding-left: 0.5em;
	width: 17em !important;
	color: #eceeef;
	margin-left: -0.5em;
}

.price-calculator__textbox-double-general-col2 {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	padding-left: 0.5em;
	width: 352px !important;
	color: #eceeef;
	margin-left: 2.5em;
}

.price-calculator__textbox-general-col2 {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	margin-bottom: 0.1em;
	padding-left: 0.5em;
	width: 100%;
	color: #eceeef;
}

.price-calculator__container-link {
	color: var(--orange-200, #ffcabf);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
	margin-left: 14em;
}

.price-calculator__btn {
	align-items: center;
	background: var(--orange-400, #ff8166);
	border-radius: 24px;
	display: flex;
	gap: 10px;
	justify-content: center;
	margin-bottom: 1em;
	margin-left: 36.6em;
	padding: 12px 20px;
	cursor: pointer;
	width: 166px;
	height: 45px;
	margin-top: 1em;
}

.price-calculator__btn-text {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
}

.transition-animation__image-container {
	width: 160px;
	height: 470px;
}

.transition-animation__image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	flex-shrink: 0;
}

.transition-animation__image {
	max-width: 80%;
	max-height: 100%;
}

.price-calculator__price-calculator-container-title {
	color: var(--gray-100, #eceeef);
	font-family: Raleway;
	font-size: 34.64px;
	font-style: normal;
	font-weight: 700;
	line-height: 48.496px;
	margin-left: 1em;
	margin-top: 0.7em;
}

.price-calculator__price-calculator-container-title-separator {
	width: 1250px;
	height: 1px;
	color: #eceeef;
}

.comparador_charts {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40em;
	width: 70em;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: 5em;
	margin-top: -2em;
}

.price-calculator__select-row {
	display: flex;
	justify-content: space-between;
	margin-left: 3em;
	margin-right: 3em;
}

.price-calculator__select-container {
	width: 174px;
	height: 50px;
}

.price-calculator__select-box {
	width: 100%;
	height: 50px;
}

.price-calculator__info-row {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	margin-left: 3em;
	margin-right: 3em;
}

.price-calculator__info-left {
	flex: 1;
	text-align: left;
}

.price-calculator__info-right {
	text-align: right;
	margin-right: 1em;
}

.price-calculator__info-right-button {
	cursor: pointer;
}

.price-calculator__info-count {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 400;
	line-height: 23.926px;
}

.price-calculator__info-order {
	color: var(--orange-200, #ffcabf);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
}

.price-calculator__labels-row {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	margin-left: 3em;
	margin-right: 6em;
	margin-bottom: 1em;
}

.price-calculator__label {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
}

.price-calculator__panel {
	display: flex;
	width: 1170px;
	padding: 16px 20px;
	align-items: center;
	gap: 32px;
	border-radius: 8px;
	background: var(--gray-700, #425563);
	margin-left: 3em;
	width: 1200px;
}

.price-calculator__image-column {
	width: 80px;
	height: 80px;
	flex-shrink: 0;
	cursor: pointer;
}

.price-calculator__text-column {
	width: 230px;
}

.price-calculator__green-panel {
	display: flex;
	padding: 10px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 8px;
	background: var(--green-400, #bed5c6);
	cursor: pointer;
}

.price-calculator__bordered-panel {
	display: flex;
	padding: 10px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 8px;
	border: 1px solid var(--green-700, #68796f);
	cursor: pointer;
}

.price-calculator__image-column {
	margin-left: 20px;
}

.price-calculator__icon-column {
	width: 104px;
	padding-left: 1.3em;
	cursor: pointer;
}

.price-calculator__text-column {
	margin-left: 32px;
	width: 230px;
}

.price-calculator__text-column-title {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 19.22px;
	font-style: normal;
	font-weight: 700;
	line-height: 26.908px;
}

.price-calculator__text-column-subtitle {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 400;
	line-height: 21.266px;
}
.price-calculator__text-column-description {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16.8px;
}

.price-calculator__green-panel {
	display: flex;
	padding: 10px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 8px;
	background: var(--green-400, #bed5c6);
	width: 129px;
}

.price-calculator__green-panel-price {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 15.09px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
}

.price-calculator__green-panel-weight {
	border-radius: 4px;
	background: var(--green-700, #68796f);
	display: flex;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16.8px;
	width: fit-content;
}

.price-calculator__row {
	align-items: start;
	margin-left: calc(-0.5 * var(--bs-gutter-x));
	display: flex;
}

.price-calculator__row .error-container {
	margin-top: 8px;
	margin-bottom: 0;
}

.price-calculator__row .col-5 {
	display: flex;
	justify-content: flex-end;
}

.price-calculator__panel-price {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 15.09px;
	font-style: normal;
	font-weight: 400;
	line-height: 23.926px;
}

.price-calculator__panel-weight {
	border-radius: 4px;
	background: var(--green-700, #68796f);
	display: flex;
	padding: 2px 8px;
	justify-content: center;
	align-items: center;
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16.8px;
	width: fit-content;
}

.price-calculator__image-flag {
	width: 36px;
	height: 24px;
}

.price-calculator__image-ico {
	width: 27px;
	height: 24px;
}

.price-calculator__image-ico-title {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 400;
	line-height: 21.3px;
	margin-bottom: 8px;
}

.price-calculator__label {
	flex: 0 0 24.5em;
	max-width: 25em;
	text-align: left;
}

.price-calculator__label:nth-child(2) {
	flex: 0 0 7em;
	max-width: 7em;
}

.price-calculator__label:nth-child(3) {
	flex: 0 0 8.5em;
	max-width: 9em;
}

.price-calculator__label:nth-child(n + 4):nth-child(-n + 7) {
	flex: 0 0 4em;
	max-width: 4em;
}

.price-calculator__paginator {
	display: flex;
	justify-content: flex-end;
	margin-right: 3em;
	margin-bottom: 1.5em;
	margin-top: 1em;
}

.price-calculator__paginator-items {
	display: flex;
	align-items: center;
}

.price-calculator__paginator-label {
	margin-right: 10px;
	color: #fff;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 500;
	line-height: 21.266px; /* 140% */
}

.price-calculator__paginator-pages {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.price-calculator__paginator-page {
	padding: 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 1px solid var(--gray-700, #425563);
	background: var(--green-900, #232825);
	color: white;
	margin-right: 5px;
}

.price-calculator__paginator-page--selected {
	border-radius: 4px;
	border: 1px solid var(--gray-700, #425563);
	background: var(--green-900, #232825);
	padding: 8px;
	justify-content: center;
	align-items: center;
}

.price-calculator__paginator-page-count {
	margin-left: 5px;
	margin-right: 10px;
	color: #fff;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 500;
	line-height: 21.266px; /* 140% */
}

.price-calculator__paginator-button {
	border-radius: 1.25em;
	background: var(--green-500, #adcab8);
	cursor: pointer;
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	gap: 0.625em;
}

.price-calculator__paginator-button-text {
	margin-right: 5px;
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.84375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.3125em;
}

.price-calculator__paginator-button--prev {
	background: var(--green-500, #adcab8);
}

.price-calculator__paginator-button--next {
	background: var(--green-500, #adcab8);
}

.price-calculator__sort-icon {
	margin-left: 0.8em;
	cursor: pointer;
}

.price-calculator__info-row {
	display: flex;
	justify-content: space-between;
	align-items: first baseline;
}

.price-calculator__price-calculator-container-title {
	flex: 1;
}

.price-calculator__info-right {
	display: flex;
	align-items: center;
}

.price-calculator__sort-icon {
	margin-right: 5px;
}

.price-calculator__new-comparator-button {
	display: inline-flex;
	height: 39px;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 20px;
	background: var(--orange-400, #ff8166);
	cursor: pointer;
}

.price-calculator-paises {
	margin-left: 100px;
}

.animationTest__next {
	margin-left: 0;
}

.price-calculator__circle {
	border-radius: 1em;
	background: var(--gray-300, #bbc2c7);
	display: flex;
	width: 1.75em;
	height: 1.75em;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.625em;
	color: var(--gray-700, #425563);
	font-family: Barlow;
	font-size: 0.949375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.49875em;
}

.price-calculator__tabs {
	display: flex;
	border-bottom: 1px solid #425563;
	border-bottom: 1px solid var(--gray-700, #425563);
	display: flex;
	height: 60px;
	padding: 0 10px 0 0;
}

.tab-price-calculator {
	flex: 1;
	font-size: 12px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
	padding: 12px 0 7px;
	color: white;
	justify-content: center;
}
.price-calculator__circle--active {
	background: var(--orange-400, #ff8166);
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.949375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.49875em;
}
.price-calculator__container {
	display: flex;
	flex-direction: column;
	border-radius: 0.75em;
	background: var(--gray-800, #2e3c46);
	position: relative;
	max-width: 800px;
	margin: 0 auto;
	width: 100%;
}

.price-calculator__container-footer {
	border-top: 0.0625em solid var(--gray-700, #425563);
	display: flex;
	padding: 1em 2.5em;
	justify-content: space-between;
	align-items: center;
	gap: 1em;
	align-self: stretch;
	width: 100%;
	height: 71px;
}

.price-calculator__footer-link {
	background: none;
	border: none;
	color: #ffcabf;
	font-family: Barlow;
	font-weight: 600;
	font-size: 15px;
	cursor: pointer;
}

.price-calc__next {
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	gap: 0.625em;
	cursor: pointer;
	border-radius: 1.25em;
	background: var(--green-500, #adcab8);
}

.btn-test-next-text {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.84375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.3125em;
}

.price-calculator__field-with-icon {
	display: flex;
	align-items: center;
}
.price-calculator__field-with-label {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
}
.price-calculator__field-label {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	font-size: 15.19px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #ffcabf;
	text-decoration: none;
}
.tarif-code_container{
	position: relative;
}

.tarif-code_button {
	position: absolute;
	top: 0;
    align-items: center;
    background: #FF8166;
    border-radius: 0px 4px 4px 0px;
    color: #1A2228;
    display: flex;
    flex-direction: row;
    font-family: 'Barlow';
    font-size: 13.5px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 50px;
    justify-content: center;
    line-height: 19px;
    padding: 10px 16px;
    position: absolute;
    right: 0;
    width: 73px;
}
.tarif-code_button-succes{
	width: 45px;
	padding: 0;
	background: #ADCAB8;
}
.modal-tc_icon {
    margin: 45px 0 0 5px;
}
.modal-tc_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 21.62px;
    line-height: 30px;
    color: #ECEEEF;
	margin: 20px 0 12px;
}

.modal-tc_text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15.19px;
    line-height: 21px;
    color: #ECEEEF;
	margin: 0 0 40px;
}

.modal-tc_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    /* width: 63px; */
    height: 39px;
    background: #FF8166;
    border-radius: 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 19px;
    color: #1A2228;
    margin-left: auto;
}
.price-calculator-mt {
	margin-top: -20px;
}

.price-calc__dropdown-general {
	display: flex;
	padding-left: 0.5em;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.25em;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	width: 100%;
	height: 50px;
	margin-bottom: 0;
	color: var(--gray-300, #bbc2c7);
	cursor: pointer;
}
.price-calculator-ml-sm {
	margin-left: 8px;
}
.price-calculator-ml-lg {
	margin-left: 12px;
}

.price-calculator__select-font {
	color: #bbc2c7 !important;
	font-family: Barlow !important;
	font-size: 15px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 15.19px;
	cursor: pointer;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.price-calculator___inner select::placeholder,
.price-calculator___inner input::placeholder {
	color: #bbc2c7;
	font-size: 13px;
}

.price-calculator___inner select {
	color: #9e9e9e;
	font-size: 15px;
	box-shadow: none;
}

.price-calculator___inner select option {
	height: 60px;
	padding: 20px;
}

option:not(:first-of-type) {
	color: white;
}

.price-calculator___inner .select-md .CustomizedSelect__container {
	width: 120px !important;
}

.price-calculator_resume {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.75em;
	background: var(--gray-800, #2e3c46);
	height: auto;
	margin-left: 0;
}

.price-calculator_resume_container {
	display: block;
}

.price-calculator_resume-footer {
	border-top: 1px solid #425563;
	padding: 24px 47px 24px 34px;
}
.price-calculator_resume-footer_loading .css-ue6sde {
	min-height: inherit;
	height: 40px;
}

.price-calculator_resume-footer_container {
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	min-width: 125px;
	border-radius: 1.25em;
	background: #ff8166;
	margin-left: auto;
}

.price-calculator_resume-title {
	color: #fff4f4;
	font-family: Raleway;
	font-size: 35px;
	line-height: 38px;
	font-size: 28px;
	line-height: 30px;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}

.price-calculator_resume-subtitle {
	color: #cedfd5;
	font-family: Raleway;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 23.926px;
}

.price-calculator_resume_container-card {
	display: flex;
	gap: 16px;
}

.price-calculator_resume_card {
	max-width: 189px;
	width: 100%;
	height: 79px;
	flex-shrink: 0;
	border-radius: 8px;
	background: var(--green-300, #cedfd5);
}

.price-calculator_resume_card.size-sm {
	background: #97a2a9;
	max-width: 147px;
}

.price-calculator_resume_card .card-body {
	padding: 15px 0 0 15px;
}

.price-calculator_resume_card-title {
	color: var(--gray-800, #2e3c46);
	font-family: Barlow;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-bottom: 4px;
}

.price-calculator_resume_card-text {
	color: var(--gray-800, #2e3c46);
	font-family: Barlow;
	font-size: 13.5px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
}

.price-calculator_resume-footer_btn {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.84375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.3125em;
}

.price-calculator_resume-footer_title {
	color: #fff4f4;
	font-family: Barlow;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
}

.price-calculator_resume-footer_text {
	color: #fff;
	font-family: Barlow, Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding-top: 0.3em;
}

.price-calculator_resume-title svg {
	font-size: 18px;
	margin-left: 12px;
}

.price-calc__tootip-link {
	color: #1a2228;
	font-weight: 500;
}

.price-calculator_resume_card-link {
	text-align: right;
	margin: 8px 4px;
}

.price-calculator_resume_card-link a {
	font-family: Barlow, Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 15px;
	color: #ffcabf;
	text-decoration: none;
}
.price-calculator_resume-footer_container--save {
	background: #adcab8;
	margin-bottom: 20px;
}

.botones-price-calculator {
	display: flex;
}

.price-calculator__row .price-calculator__textbox-double-general-col2 {
	width: auto !important;
}

.price-calculator___container .row {
	gap: 24px;
}
.label-container {
	position: relative;
}
.label-input {
	position: absolute;
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #bbc2c7;
	top: 7px;
	left: 9px;
	z-index: 2;
}
.col-12.label-container label {
	left: 21px;
}

.label-container input {
	padding-top: 11px;
}
form {
	max-width: 100%;
	width: 100%;
}
.price-calculator_resume-subtitle,
.price-calculator_resume-title {
	padding: 0 6px;
}
.price-calculator_resume_container-card {
	max-width: 100%;
	flex-direction: column;
	margin: 0 20px;
}
.price-calculator_resume_container_row {
	margin: 25px 0;
}
.price-calculator_resume_card,
.price-calculator_resume_card.size-sm {
	max-width: inherit;
	width: 100%;
}
.price-calculator_resume_card-link {
	margin: 15px 20px;
}
.price-calculator__result {
	margin-left: -8px;
	margin-right: -8px;
}
.price-calculator_resume-footer {
	padding: 24px 20px 24px;
	margin: 0;
}
.botonesCubicajeContainer {
	margin-bottom: 20px;
}


.errorcalculator {
    padding: 60px 20px;
    display: flex;
    gap: 20px;
	align-items: center;
    flex-direction: column;
}

.errorcalculator__txt {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 21.62px;
    line-height: 30px;
    color: #ECEEEF;
    margin:  0 0;
}
@media print {
	/* All your print styles go here */
	#header,
	#footer,
	.price-calculator_resume-title svg,
	.price-calculator_resume_card-link,
	#nav {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.price-calculator___container .row {
		gap: 0;
	}

	.price-calculator__tabs {
		display: flex;
		border-bottom: 1px solid var(--gray-700, #425563);
		padding-left: 0;
		height: 60px;
	}

	.tab-price-calculator {
		display: flex;
		font-size: 15px;
		align-items: center;
		width: auto;
		flex-direction: row !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0.75em 1.25em;
		border-bottom: 0.1875em solid #2e3c46;
		cursor: pointer;
		min-width: 5em;
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0.4375em;
		margin-right: 0.4375em;
		flex: inherit;
		gap: 12px;
	}

	.price-calculator__circle {
		border-radius: 1em;
		background: var(--gray-300, #bbc2c7);
		display: flex;
		width: 1.75em;
		height: 1.75em;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625em;

		color: var(--gray-700, #425563);
		font-family: Barlow;
		font-size: 15px;
		line-height: 23px;
		font-style: normal;
		font-weight: 600;
	}

	.price-calculator__circle--active {
		background: var(--orange-400, #ff8166);
		color: var(--gray-900, #1a2228);
		font-family: Barlow;
		font-style: normal;
		font-weight: 600;
		/* font-size: 0.949375em;
    line-height: 1.49875em; */
	}

	.text-price-calculator {
		color: var(--gray-300, #bbc2c7);
		font-family: Barlow;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 23px;
	}

	.text-price-calculator-active {
		color: #eceeef;
		font-family: Barlow;
		font-size: 0.949375em;
		font-style: normal;
		font-weight: 600;
		line-height: 1.49875em;
	}

	.price-calculator_resume_container_row {
		margin: 25px 0;
		width: 100%;
	}

	.price-calculator_resume_container-card {
		flex-direction: row;
	}

	.price-calculator_resume_card,
	.price-calculator_resume_card.size-sm {
		width: auto;
		flex: 1;
	}

	.price-calculator-first {
		flex-wrap: inherit;
	}

	.price-calculator-first .price-calculator-btn {
		order: 1;
		flex-basis: inherit;
		width: 135px;
		margin-left: 8px;
	}
	.price-calculator_resume-footer_container--save {
		margin-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.price-comparator__info-right.price-comparator__info-order.price-comparator__info-right-button {
		margin-left: auto;
		margin-right: 50px;
	}
	.price-comparator__info-row {
		padding: 40px 40px 20px;
		align-items: first baseline;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}

	.price-comparator__price-comparator-container-head {
		font-size: 34.64px;
		line-height: 40px;
	}

	.price-comparator__price-comparator-container-head-title {
		font-size: 19px;
		line-height: 22px;
	}
}

@media (min-width: 1440px) {
	.price-calculator_resume_container_row {
		margin: 40px 0;
		padding: 0 20px;
	}
	.price-calculator_resume_card-link {
		margin: 15px 40px;
	}
	.price-calculator_resume-title {
		font-size: 35px;
		line-height: 38px;
	}

	.price-calculator_resume_container-card {
		padding: 0 20px;
	}

	.price-calculator__result {
		margin: 0px 15px;
	}
	.price-calculator_resume-footer {
		padding: 24px 40px 24px;
		margin: 0;
	}
}

.mainExporterTestContainer {
  margin: 40px auto 0;
  max-width: 1225px;
  width: 100%;
}

.block-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.exporterTest__row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 30px;
}

.exporterTest__fixed .stickyVideo img {
  max-height: 150px;
  width: auto;
  margin: 0 auto;
  display: block;
}

.container-news-sgg {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.content-news-sgg {
  display: flex;
  max-width: auto;
  margin-top: 1em;
}

.main {
  flex-grow: 1;
  flex-basis: auto;
  width: 46.5625em;
  margin-right: 1.2em;
}

.suggestions {
  flex-shrink: 0;
  flex-basis: auto;
  width: 25.4375em;
}

.dark-overlay {
}

.dark-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8888;
}

.activate-overlay {
  position: relative;
  z-index: 9999;
}

.test-exporter-container {
  display: flex;
  flex-direction: row;
  
}

.stickyVideo {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.exporter-test__switch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.exporter-test__switch .exporter-test__question{
  margin-bottom: 5px;
}



@media (min-width: 768px) {
  .exporterTest__fixed .stickyVideo img {
    max-height: 300px;
  }  
}

@media (min-width: 1024px) {
    
    .exporterTest__row {
        flex-direction: row;
        align-items: flex-start;
        gap: 0;
    }
    .exporterTest__flex.test-exporter-container {
        padding-right: 30px;
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    
    .exporterTest__fixed {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

@media (min-width: 1440px) {
  .exporterTest__flex.test-exporter-container {
      padding-right: 70px;
  }
}


main {
  position: relative;
}

.container-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  filter: drop-shadow(0px 4px 24px rgba(26, 34, 40, 0.2));
  max-width: 100%;
  background-color: #2e3c46;
  border-radius: 12px;
  box-sizing: border-box;
}

.navtabs {
  display: flex;
  gap: 1em;
  border-bottom: 1px solid var(--gray-700, #425563);
  padding-left: 0;
  width: 100%;
  overflow: scroll;
}

.navtabs__tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 21px 20px 12px;
  border-bottom: 3px solid #2e3c46;
  cursor: pointer;
  min-width: 80px;
  margin-left: 7px;
  margin-right: 7px;
  border-bottom: 0px solid #ff5c39;
  transition: .2s all ease-out;
  gap: 10px;
}

.navtabs__tab-next {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 3px solid #2e3c46;
  cursor: pointer;
  min-width: 80px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 7px;
  margin-right: 7px;
}

.navtabs__tab:hover {
  border-bottom: 3px solid #ff5c39;
}

.navtabs__tab:hover:last-child {
  border-bottom: transparent;
}

.navtabs__tab.active {
  border-bottom: 3px solid #ff5c39;
}

.navtabs__tab__icon {
  width: 30px;
  height: 27.5px;
  flex-shrink: 0;
}

.navtabs__tab__text {
  color: var(--green-200, #dfeae3);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.266px;
  /* 140% */
}

.tab-content-main-footer-filter span,
.preference {
  padding: 0.3% 1.5%;
  font-size: 12px;
  border-radius: 8px;
  background-color: #425563;
  color: #D4D8DB;
  transition: .2s all ease-out;
}

.preference:not(:last-child):hover {
  border-radius: 8px;
  background: var(--gray-900, #1a2228);
}
.preference-text{
  margin: 0;
  
}

.preference-news:hover {
  border-radius: 8px;
  background: var(--gray-900, #1a2228);
}

.tab-content-main {
  display: flex;
  align-items: center;
  gap: 0.7em;
  height: auto;
  max-width: 100%;
  background-color: #2e3c46;
  border-radius: 8px;
}

.tab-content-main {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.tab-content-secondary {
  display: flex;
  align-items: center;
  gap: 0.8em;
  height: auto;
  max-width: 100%;
  background-color: #2e3c46;
  border-radius: 8px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.tab-content-secondary-photo {
  align-self: stretch;
  margin-left: 1em;
}

.tab-content-secondary-photo img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  width: 129px;
  height: 129px;
}

.tab-content-main-photo,
.tab-content-photo {
  align-self: stretch;
  margin-left: 1em;
}

.tab-content-main-photo img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  width: 284px;
  height: 205px;
}

.tab-content-main-summary {
  flex: 1.2;
  margin-right: 1em;
  margin-left: 0.5em;
}

.tab-content-summary {
  flex: 3.2;
}

.tab-content-title {
  color: #fff;

  font-family: Raleway;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  /* 140% */
}

.tab-content-text {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: 1em;
}

.tab-content-main-footer-filter {
  margin: 30px 0 10px 0;
}

.tab-content-main-footer-filter span {
  color: #d4d8db;
}


.tab-content-footer {
  display: flex;
  align-items: center;
  gap: 2em;
  align-self: stretch;
  margin-top: 0.5em;
}

.tab-content-main-footer-source,
.tab-content-main-footer-date {
  color: #b8b8b8;
  margin-right: 10px;
  font-size: 12px;
}


.tab-content-footer-source {
  color: var(--green-500, #adcab8);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  /* 140% */
}


.preference {
  padding: 4px 12px;
  cursor: pointer;
}

.preference-news {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

/* 
.ver-todos-text {
  color: var(--orange-200, #ffcabf);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
} */


.btn-next {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: #ff5c39;
}

.btn-next:hover {
  color: #ff8166;
}


@media (max-width: 70em) {
  .tab-icon {
    height: 32px;
    width: 32px;
  }

  .tab-text {
    font-size: 14px;
  }
}




.content-btn-news-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tagActive {
  background-color: #bed5c6;
}

.tagActive p {
  color: #fff;
}

.tabs__more{
	margin: 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  /* padding: 0.5em; */
  width: 100%;
  flex-wrap: wrap;
}
.tabs__more__text{
	color: var(--orange-200, #ffcabf);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  /* margin-left: 1em; */
}

.tabs__more__text:hover {
	color: #b27571;
	cursor: pointer;
  }

.tabs__body{
	padding: 20px 20px;
	max-width: 100%;
	width: 100%;
}
.itemnews + .itemnews{
	margin-top: 10px;
}

.itemnews__content {
	display: flex;
  flex-direction: column;
  
  /* padding: 10px; */
  width: 100%;
}

.itemnews__row{
	display: flex;
	flex-direction: column;
}

.itemnews__fixed {
	width: 100%; 
	aspect-ratio: 320 / 210; 
}
.itemnews__img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin: 0;
	border-radius: 8px;
  cursor: pointer;
}
.itemnews__flex {
	padding: 20px 0;
	display: flex;
  flex-direction: column;
	gap: 14px;
}

.itemnews__title{
	color: #fff;
  font-family: Raleway;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
	margin: 0;
  cursor: pointer;
}
.itemnews__text{
	color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
	margin: 0;
  cursor: pointer;
}
.itemnews__footer{
	display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemnews__footer__col{
	display: flex;
	gap: 12px;
}
.itemnews__tag,
.itemnews__date{
	color: var(--green-500, #ADCAB8);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.itemnews__actions{
	display: flex;
	align-items: center;
	gap: 10px;
}
.itemnews__share{
	margin-left: auto;
	flex-shrink: 0;
	border-radius: 20px;
	width: auto;
	height: auto;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-left: 5em;
	fill: white;
}
.itemnews__share__icon{
  fill: white;
}

.itemnews__share__icon:hover{
	fill: grey;
}

  
  
  
.itemnews__save{
	fill: white;
	margin-left: 1em;
	cursor: pointer;
}
.itemnews__save img{
	width: 20px;
	height: 16px;
}

.itemnews__save:hover {
	fill: grey;
}
  
.itemnews__fillter {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.itemnews__preference__text {
	padding: 3px 5px;
	text-align: center;
	font-size: 14px;
	background-color: #425563;
	padding: 3px 7px;
	border-radius: 8px;
	color: var(--gray-200, #d4d8db);
}
.itemnews__preference__text,
.itemnews__preference{
	margin: 0;
}
.itemnews__preference__text:empty {
    display: none;
}
.itemsnews__footer{
	position: relative;
  margin-top: 30px;
}
.itemsnews__footer__btn{
	margin-top: 1.5em;
  margin-left: 23em;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* left: 50%; */
  bottom: 2em;
  /* transform: translateX(-50%); */
  border-radius: 20px;
  background: var(--green-500, #adcab8);
  cursor: pointer;
	width: 100%;
	margin: 0 auto;
	max-width: 230px;
}
.itemsnews__footer__text{
	color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.9px;
}
.itemsnews__footer__btn:hover {
	border-radius: 20px;
	background: var(--green-400, #bed5c6);
	cursor: pointer;
}
  

@media (min-width: 768px) {

	.itemnews__fixed {
		height: 130px;
		width: 130px;
		aspect-ratio: 130 / 130;
	}
	.itemnews--big .itemnews__fixed {
		height: 175px;
		width: 217px;
		aspect-ratio: 175 / 217;
	}
	.itemnews--big .itemnews__flex{
		padding: 11px 0;
		gap: 4px;
	}
	.itemnews--big  .itemnews__footer {
		margin: 5px 0 9px;
	}
	.itemnews--big  .itemnews__text{
		-webkit-line-clamp: 3;
	}
	.itemnews__row {
		display: flex;
		flex-direction: row;
		gap: 24px;
	}
	.itemnews + .itemnews {
		margin-top: 32px;
		margin-bottom: 10px;
	}
	.itemnews__flex {
		padding: 2px 0 ;
		gap: 4px;
	}
	.itemnews__footer {
		margin: 5px 0 9px;
	}
	.itemnews__title {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.itemnews__text{
		-webkit-line-clamp: 2;

	}
}
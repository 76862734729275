.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(26 34 40 / 70%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background: #2E3C46;
    color: white;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08), 0px 25px 50px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px;
    width: 90%;
    max-width: 500px;

    position: relative;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 24px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .popup-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  
  .popup-message {
    margin-bottom: 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15.19px;
    line-height: 21px;
    color: #D4D8DB;
}
  
  .popup-content {
    margin-top: 10px;
  }
  

  .popup-button{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    /* width: 85px; */
    height: 39px;
    background: #97A2A9;
    border-radius: 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 19px;
    color: #1A2228;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.save-button {
    background: #FF8166;
}
.replace-button{
    background: #ADCAB8;
}
.popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.popup-edit{
    position: relative;
}
.popup-button-full{
  width: 100%;
}
.popup-label{
    position: absolute;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #BBC2C7;
    top: 7px;
    left: 15px;
}
.popup-input{
    
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15.19px;
    line-height: 21px;

    color: #ECEEEF;
    width: 100%;
    box-sizing: border-box;
    background: #425563;
    box-shadow: inset 0px -1px 0px #5E6F7A;
    border-radius: 4px 4px 0px 0px;
    padding: 25px 10px 10px 15px;
    margin-bottom: 30px;
}

.popup-icon{
  margin-bottom: 30px;
}
.popup-action-title{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 21.62px;
  line-height: 30px;
  color: #ECEEEF;
  
}
.popup-action-message{
  
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15.19px;
  line-height: 21px;
  color: #ECEEEF;
  margin-bottom: 30px;

}

.price-calculator-container_father .css-ue6sde{
  min-height: 340px; 
}
.replace-loading .css-ue6sde{
  min-height: 42px;
}
.list-calculos .css-ue6sde{
  min-height: 100px;
}
.popup-packs .popup-container{
  padding: 0;
  max-width: 800px;
  box-shadow: 0px 0 0 rgba(0, 0, 0, 0.08), 0px 0 0  rgba(0, 0, 0, 0.15);
}
.popup-packs .popup-content{
  margin-top: 0;
  border-radius: 12px;
  box-shadow: 0px 0 0 rgba(0, 0, 0, 0.08), 0px 0 0  rgba(0, 0, 0, 0.15);
}
.popup-message .popup-container{
  padding: 20px 40px 32px;
}
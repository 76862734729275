.chs_modal {
    
    max-width: 440px;
    margin: 0 auto;
}
.chs_modal_icon {
    margin: 17px 0 34px 7px;
}
.chs_modal_title {
    margin: 0;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 21.62px;
    line-height: 30px;
    color: #ECEEEF;
    margin: 0 0 28px;
}

.chs_modal_text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15.19px;
    line-height: 21px;
    color: #ECEEEF;
    margin: 0 0 29px;
}
.chs_modal_body{
    margin: 0 0 29px;
}

.chs_modal_actions {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.chs_modal_button {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 15.19px;
    line-height: 21px;
    color: #1A2228;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    height: 46px;
    background: #FF8166;
    border-radius: 24px;
    text-decoration: none;
    transition: .2s all ease-out;
}
.chs_modal_button:hover{
    background: #df7057;
}
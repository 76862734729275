.packs{
    background: #2E3C46;
    padding: 20px;
    color: white;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 12px;
}

.packs_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 19.22px;
    line-height: 27px;
    color: #ECEEEF;
    letter-spacing: -0.01em;
    
    margin: 0 0 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
    justify-content: flex-start;
}

p.packs_subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 17.09px;
    line-height: 24px;
    color: #D4D8DB;
    letter-spacing: -0.01em;
    margin: 0 0 20px;
}

.packs_items_title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 17.09px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #1A2228;
    margin: 0 0 3px;
}

p.packs_items_desc {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #1A2228;
    margin: 0;
}

p.packs_unique_title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    
    letter-spacing: -0.01em;
    color: #2E3C46;
    margin: 0;
}

p.packs_unique_price {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #1A2228;
    margin: 4px 0 0;
}


.packs_items_button {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 19px;
    color: #ECEEEF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 30px;
    align-self: flex-end;
    background: #2E3C46;
    border-radius: 20px;
    margin: 0;
    width: 100%;

    transition: .2s all ease-out;   
}
.packs_items_button:hover{
    background: #1b252b;
}
.packs_items_button--grey{
    color: #1A2228;
    background: #ADCAB8;
}
.packs_items_button--grey:hover{
    background: #8cb29a;
}

.packs_item {
    position: relative;
    flex: 0 0 138px;
    /* width: 138px; */
}

.packs_items {
    display: flex;
    gap: 20px;
    flex-direction: column;
}


.packs_items_bottom {
    background: #D2EF5A;
    padding: 15px 14px 16px;
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    min-height: 126px;
}

.packs_unique {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}



.packs_items_top {
    background: #D2EF5A;
    padding: 16px 14px 13px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #D5E263;
}

.packs_item--grey .packs_items_bottom ,
.packs_item--grey .packs_items_top {
    background: #EFF4F1;
}
.packs_item--grey  .packs_items_top{
    border-bottom: 1px solid #D4D8DB;
}

.packs_unique {
    margin: 0 0 6px;
}


.packs_footer {
    margin: 24px -20px 0;
    padding: 20px 20px 0;
    border-top: 1px solid #425563;
}

.packs_foooter_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #D4D8DB;
    margin: 0;
}
.packs_foooter_text span{
    font-weight: 600;
    color: #FFA692;
}

.packs_title_label{    
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #1A2228;
    background: #EFFF60;
    border-radius: 6px;
    padding: 2px 1px 2px 7px;
    margin: 4px 0 0 8px;
    height: 21px;
}
.packs_title_label_icon {
    margin: -3px 5px 0 0;
}
.packs_item_overflow{
    background: rgba(26,34,40,.8);
    border-radius: 7px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.packs_item_overflow_circle {
    background: #8BA293;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 125px;
}
.packs_item_svg {
    position: relative;
    top: 1px;
}

@media (min-width: 768px) {
    .packs{
        padding: 20px 20px 20px;
    }


    .packs_items {
        flex-direction: row;
        gap: 16px;
    }
    .packs_item{
        flex: 1; 
    }
    .packs_items_button{
        width: 100%;
    }
}
.footer {
  
  color: #e3efea;
  justify-content: flex-end;
  flex-direction: column;
  height: 260px;
  padding-bottom: 100px;
  display: flex;
  margin: 100px auto 0;
  max-width: 1180px;
  width: 100%;
}

.footer__row{
  display: flex;
  gap: 11px;
  flex-direction: column-reverse;
  align-content: space-around;
  align-items: center;
  width: 100%;
}


.footer__col--left {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 140% */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

}

.footer__col--left span:hover {
  color: #f5f5f5;
}


.footer__social {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.footer__col--right {
  display: flex;
  gap: 10px;
}

.footer__youtube svg {
  height: auto;
  width: 22px;
}
.footer__facebook svg {
  height: 21px;
  width: auto;
}
.footer__instagram svg {
  height: 19px;
  width: auto;
}
.footer__linkedin svg {
  height: 19px;
  width: auto;
}





@media (min-width: 768px) {
  .footer__col--left{
    flex-direction: row;
    justify-content: center;
    gap: 55px;
  }
  .footer {
    padding-bottom: 30px;
    margin: 50px auto 0;
  }
}

@media (min-width: 1024px) {

  .footer__row{
    flex-direction: row;
  }
  .footer__col--left {
    flex-direction: row;
    justify-content: flex-start;
    gap: 55px;
  }
}

@media (min-width: 1440px) {}
.lang-dropdown{
    position: relative;
}
.lang-dropdown__toggle{
    display: flex;
    align-items: center;
    gap: 10px;
}
.lang-dropdown__content.active {
    display: flex;
    flex-direction: column;
    background: #303c46;
    width: 45px;
    gap: 5px;
    padding: 10px;
    border-radius: 7px;
    top: 33px;
    left: -10px;
    box-shadow: 0 8px 40px #000000ab;
}
.lang-dropdown__icon.fa-caret-up {
    top: 2px;
    position: relative;
}
.lang-dropdown__content{
    display: none;
    position: absolute;
}
.lang-dropdown__lang{
    border-radius: 125px;
    border: 2px solid transparent;   
    width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
}
.lang-dropdown__lang svg{
    position: absolute;
    top: 0px;
}
.lang-dropdown__lang.selected{
    border: 2px solid #d4d8db;
}



.chout {
    
    max-width: 480px;
    margin: 0 auto;
    color: #FFF4F4;
    
}

.chout_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #FFF4F4;
    margin: 0 0 16px;
}

.chout_subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #DFEAE3;
    margin: 0 0 25px;
}

.chout_label {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #BBC2C7;
}
.chout_discount{
    background: #DFFF60;
    border-radius: 8px;
    display: flex;
    color: #2E3C46;
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.chout_discount_label {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #2E3C46;
    margin: 0 0 0;
}


p.chout_discount_desc {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #2E3C46;
    margin: 0;
}

.chout_discount_text {
    margin: 0;
    color: #2E3C46;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 19.22px;
    line-height: 21px;
    text-align: right;
    letter-spacing: -0.01em;
    margin: 0;
    display: flex;
    gap: 8px;
}

span.chout_discount_old {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 21px;
    text-align: right;
    letter-spacing: -0.01em;
    text-decoration-line: line-through;
    color: #2E3C46;
}

.chout_footer_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #D4D8DB;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    margin: 0;
}
.chout_footer_text span{
    font-weight: 600;
}

.chout_next {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 19px;
    color: #1A2228;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 39px;
    background: #FF8166;
    border-radius: 20px;
    transition: .2s all ease-out;   
    width: 100%;
}
.chout_next:hover{
    background: #df7057;
}
.chout_footer {
    margin: 20px -20px 0;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-top: 1px solid #425563;

    flex-direction: column-reverse;
    gap: 15px;
    padding: 20px 20px 0;
}


.chout_form {
    display: flex;
    flex-direction: row;
    margin: 0 0 22px;
}

.chout_form_body {
    position: relative;
    flex: 1;
}

label.chout_label {
    position: absolute;
    top: 10px;
    left: 12px;
}

.chout .chout_input {
    background: #425563;
    border-bottom: 1px solid #5E6F7A;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 15.19px;
    line-height: 21px;
    color: #CEDFD5;
    padding: 22px 12px 6px 12px;
    width: 100%;
}

.chout_next--disabled:hover,
.chout_next--disabled{
    background: #97A2A9;
    cursor: auto;
    color: #1A2228;
}
.chout  .chout_input--error{
    border-bottom: 1px solid #F8A0D2;
}
.chout_error{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 10px 0 0;
}
.chout_error_message{
    margin: 0;
    color: #F8A0D2;
    font-family: 'Barlow';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

}
.chout__loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    
    .css-ue6sde{
        min-height: 40px;
    }
    
}
.chout_submit {
    background: #FF8166;
    color: #1A2228;
    border-radius: 0 4px 4px 0px;
    flex: 0 0 75px;
    text-align: center;
    
    font-family: 'Barlow';
    font-size: 13.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    height: 50px;
    cursor: auto;
    opacity: .6;
}
.chout_submit--active{
    background: #FF8166;
    color: #1A2228;
    cursor: pointer;
    opacity: 1;
}
.chout_submit--checked{
    background: #ADCAB8;
    color: #ECEEEF;
    cursor: pointer;
    flex: 0 0 50px;
}
.chout_submit .css-ue6sde{
    min-height: 40px;
}
.chout_reset {
    position: absolute;
    right: 12px;
    top: 14px;
}
.chout_to_pay {
    background: #DFFF60;
    border-radius: 8px;
    display: flex;
    color: #2E3C46;
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chout_to_pay_label {
    margin: 0;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #2E3C46;
}

.chout_to_pay_text {
    margin: 0;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 19.22px;
    line-height: 21px;
    text-align: right;
    letter-spacing: -0.01em;
}
@media (min-width: 768px) {
   
    .chout_footer {
        
        margin: 20px -20px 0;
        flex-direction: row;
        gap: 0;
        
    }
    .chout_next{
        width: auto;
    }
}
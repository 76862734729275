.price-calc__container-page {
    margin: 40px auto 0;
    max-width: 1225px;
    width: 100%;
}

.price-calc__header {
	margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.price-calc__principal-sub-title {
	color: var(--gray-200, #D4D8DB);
	font-family: Barlow;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 400;
	line-height: 23.926px;
    
}

.price-calc__principal-title {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    color: var(--gray-100, #eceeef);
    font-family: Barlow;
    align-items: center;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}

.price-calc__principal-title svg {
    font-size: 18px;
}

.price-calc__tootip-link {
    border: none;
    background: none;
    padding: 0;
    font-size: 11px;
    text-decoration: underline;
    font-weight: 700
}
.price-calc__row{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
}
.price-calc__flex,
.price-calc__fixed {
    width: 100%;
}
.calc__fixed__video{
    padding-top: 56.25%;
    position: relative;
    display: block;
    width: 100%;
    max-width: 300px;
    height: 160px;
    padding-top: 0;
    margin: 0 auto;
}
.calc__fixed__video > div{
    position: absolute;
    top: 0;
    left: 0;
}
.calc__fixed__video video {
    object-fit: cover;
}



@media (min-width: 768px) {
    .calc__fixed__video{
        padding-top: 56.25%;
        
        max-width: inherit;
        height: inherit;
        
        margin: 0;
    }
}

@media (min-width: 1024px) {

    .price-calc__principal-title {
        font-size: 35px;
        line-height: 48px;
    }

    .price-calc__row{
        flex-direction: row;
        align-items: flex-start;
        gap: 0;
    }

    .price-calc__flex{
        flex: 0 0 auto;
        width: 66.66666667%;
        padding-right: 30px;
    }
    .price-calc__fixed{
        
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

@media (min-width: 1440px) {
    .price-calc__flex{
       
        padding-right: 70px;
    }
}






/* 
@media (min-width: 768px) {}

@media (min-width: 1024px) {}

@media (min-width: 1440px) {} 
*/
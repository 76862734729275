
.popup-corp .popup-container {
    padding: 16px;
    max-width: 480px;
}
.popup_corp_title {
    color: #ECEEEF;
    font-family: 'Raleway';
    font-size: 19.22px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 25px;
}

.popup_corp_body {
    color: #D4D8DB;
    display: flex;
    flex-direction: column;
    font-family: 'Barlow';
    font-size: 15.19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.popup_corp_button {
    background: #FF8166;
    border-radius: 20px;
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 13.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-left: auto;
    padding: 10px 16px;
}

.popup_corp_footer {
    border-top: 1px solid #425563;
    display: flex;
    margin: 0 -16px;
    padding: 16px 16px 0;
}



@media (max-width: 80em) {
  .authHeader {
    margin-bottom: 2%;
    margin-top: 1%;
    align-self: center;
    justify-content: center;
    display: flex;
    width: 90%;
  }

  .header__top {
    display: flex;
    flex-direction: row;
    gap: 1.075em;
    flex-grow: 0;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    /* margin-bottom: 5em; */
  }

  .dropdown__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 320px;
    left: 50%;
    top: 69px;
    background-color: #2e3c46;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.67);
    border-radius: 1.5em;
    z-index: 10;
  }
}

@media (max-width: 700px) {
  .header__top {
    display: flex;
    flex-direction: row;
    gap: 2.075em;
    flex-grow: 0;
    flex-wrap: wrap;
    width: 100%;
    /* margin-bottom: 20em; */
  }
}

.hide-mobile{
  display: none;
}







.authHeader {
  margin-bottom: 2%;
  margin-top: 1%;
  align-self: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.notAuthHeader {
  margin-bottom: 2%;
  margin-top: 1%;
  align-self: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.custom-col-md {
  display: contents;
}

.navbar {
  display: flex;
  justify-content: center;
  position: relative;

  background: #2f3c46;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 20px;
  width: 100%;
  z-index: 999;
}

.navbar__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap ;
  margin: 0 auto;
  max-width: 1216px;
  position: relative;
  width: 100%;
}

.navbar-logout {
  /* margin-left: 22em; */
}

.header__top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 1.075em;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  flex-grow: 0;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.frame-logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 40em;
  width: 100%;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.header__tools{
  display: none;
}

.header__logo {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 15px;
}
.header__right{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  justify-content: flex-end;
}
.interconectar-menu-login {
  /* margin-right: 60em; */
}

.frame-19 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 40px;
  width: 190px;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  margin-right: 1em;
}

/* Frame 20 */
.header__home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: #425563;
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  transition: .2s all ease-out;
}
.header__home:hover {
  background: #3d4c59;
}

/* Frame 28 */
.header__search {
  display: none;
  position: relative;
}
ul.filter-search {
  position: absolute;
  max-width: 100%;
  padding: 0;
  left: 0;
  top: 65px;
  background: #435563;
  padding: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 5px 10px #00000017;
}

.filter-search__figure {
  max-width: 50px;
  height: 50px;
  
  background: white;
  border-radius: 8px;
}

.filter-search__figure img {
  max-width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
}

li.filter-search__item {
  display: flex;
  gap: 12px;
}

h2.search__title {
  font-size: 13px;
  margin: 0;
  color: #cee0d5;
  color: white;
}

.filter-search__data {
  display: flex;
  align-items: center;
}

/* Frame 21 */
.frame-21 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: #ff5c39;
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: -2em;
}


/* Frame 22 */
.header__tools__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: #cedfd5;
  border-radius: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  transition: .2s all ease-out;
}

/* Frame 23 */
.frame-23 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: #cedfd5;
  border-radius: 20px;
  flex: none;
  order: 2;
  flex-grow: 0;
}


.frame-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  flex: none;
  order: 4;
  flex-grow: 0;
  cursor: pointer;
  padding-left: 15em;
}

/* Cuenta */
.header__account__text {
 display: none;
}

/* Frame 22 for the second group */
.header__account__preferences {
  display: none;
}

/* Frame 23 for the second group */
.header__account__bookmark {
  display: none;
}
.header__tools__text{
  display: none;
}

/* Frame 32 */
.header__user {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: var(--orange-500, #ff5c39);
  order: 2;
}
.header__account{
  order: 2;
}

/* Frame 31 */
.frame-31 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 32px;
  height: 32px;
  background: #faf1e7;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* D */
.d {
  width: 11px;
  height: 16px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1a2228;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.image-container {
  position: relative;
  width: 25px;
  height: 25px;
  order: 4;
  cursor: pointer;
}

.lng-login-img {
  width: 17px;
  height: 15px;
  object-fit: cover;
}

.arrow-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 40px;
  color: #ffffff;
  margin-left: 22px;
  color: #ffffff;
}
.btnlang{
  border: 2px solid transparent;
  border-radius: 125px;
}
.btnlang-selected{
  border: 2px solid #d4d8db;
  border-radius: 125px;
}

.dropdown__user {
  left: inherit;
  right: 0;
  /* left: 73.5%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 320px;

  top: 69px;
  background-color: #2e3c46;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.67);
  border-radius: 1.5em;
  z-index: 10;
}

.header {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #2e3c46;
  color: #1a2228;
}

.language-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.language-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}


.option .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}

.option .icon .icon-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #d9d9d9;
}

.option .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.logout {
  color: var(--orange-200, #ffcabf);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.logout .logout-icon {
  width: 14px;
  height: 14px;
  background: #ffcabf;
}


/* Frame 84 */
.dropdown-menu-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 320px;
  height: 332px;
  top: 4.5em;
  left: 0;
  background-color: #2e3c46;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.67);
  border-radius: 1.5em;
  z-index: 10;
}

.frame-80 {
  display: flex;
  padding: 20px;
  align-items: center;
  /* Centra verticalmente los elementos */
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 12px 12px 0 0 ;
  border-bottom: 1px solid var(--gray-700, #425563);
  background-color: #2e3c46;
}

.frame-79,
.frame-78,
.frame-81 {
  display: flex;
  min-width: 120px;
  padding: 24px 19px;
  flex-direction: column;
  align-items: center;
  /* Centra horizontalmente los elementos */
  justify-content: center;
  /* Centra verticalmente los elementos */
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--gray-700, #425563);
  cursor: pointer;
}

.text-btn {
  color: var(--green-200, #dfeae3);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  margin-top: 0.5em;
}

.tab-content-footer-share {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  /* Centrar horizontalmente la imagen */
}

/* Frame 83 */
.frame-83 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  gap: 12px;
  width: 320px;
  height: 72px;
  background-color: #2e3c46;
  border-radius: 0 0 12px 12px;
}
.frame-83 a{
  display: flex;
}

/* Frame 98 */
.frame-98 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
}

/* Frame 99 */
.frame-99 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
}

/* Frame 100 */
.frame-100 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
}

/* Frame 101 */
.frame-101 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
}

.fa-instagram,
.fa-linkedin,
.fa-facebook-f,
.fa-square-facebook,
.fa-youtube {
  height: 25px;
  color: #f7fbff;
}

.fa-instagram,
.fa-linkedin,
.fa-square-facebook,
.fa-youtube {
  height: 19px;
}

.header__btn-login {

  align-items: center;
  background: var(--orange-400, #ff8166);
  border-radius: 30px;
  color: var(--gray-900, #1a2228);
  cursor: pointer;
  display: flex;
  font-family: Barlow;
  font-size: 15.09px;
  
  font-style: normal;
  font-weight: 600;
  gap: 10px;
  height: 45px;
  
  justify-content: center;
  line-height: 23.926px; /* 140% */
  padding: 16px 16px;
  
  
}



.header__contenedor-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 4px 16px;
  gap: 0.6em;
  width: auto;
  height: auto;
  flex: none;
  order: 4;
  flex-grow: 0;
  width: 247px;
  cursor: pointer;
}

.header__contenedor-img-arrow-lng {
  position: relative;
  width: 25px;
  height: 25px;
  order: 4;
  cursor: pointer;
}

.header__img-arrow-lng {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 40px;
  color: #ffffff;
  margin-left: 22px;
  color: #ffffff;
}

.linkPreferencesText {
  cursor: pointer;
  text-decoration: none;
}




.dropdown__user__header {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #2e3c46;
  color: #1a2228;
}
.dropdown__user__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown__user__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #ffcabf;
  border-radius: 24px;
  color: #1a2228;
}

.dropdown__user__language {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}



.dropdown__user__title {
  font-weight: 600;
  font-size: 17.09px;
  line-height: 24px;
  color: #ffffff;
}

.dropdown__user__email {
  font-weight: 400;
  font-size: 13.5px;
  line-height: 19px;
  color: #d4d8db;
}

.dropdown__user__otions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #2e3c46;
}

.dropdown__user__option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 102px;
  background: #cedfd5;
  border-radius: 8px;
  background: var(--gray-700, #425563);
}

.dropdown__user__text {
  color: var(--green-200, #dfeae3);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  margin-top: 0.5em;
}


.dropdown__user__footer {
  color: #e3efea;
    justify-content: space-around;
    flex-direction: row;
    padding-bottom: 30px;
    display: flex;
    width: 100%;
    align-items: center;
}

.dropdown__user__guide {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #cedfd5;
}


.headerdropdown {

  order: 1;
}
.headerdropdown-title{
  margin: 0 0 16px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #CEDFD5;
}
.headerdropdown-menu {
  left: inherit;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 354px;
  height: 382px;
  top: 69px;
  background-color: #2e3c46;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.67);
  border-radius: 1.5em;
  z-index: 10;
  padding: 30px;
}

.headerdropdown-body {
  width: 100%;
}

.headerdropdown-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual ancho */
  gap: 15px; /* Espacio de 10px entre elementos */
  
}

.headerdropdown-button {
    display: flex;
    background: #435563;
    border-radius: 125px;
    height: 48px;
    width: 66px;
    justify-content: center;
    align-items: center;
    gap: 2px;
}
.headerdropdown-button.active > svg { 
  transform: rotate(180deg);
}
.headerdropdown-button-icon {
    background: #CEDFD5;
    height: 32px;
    width: 32px;
    border-radius: 125px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.headerdropdown-body {
  width: 100%;
}
.headerdropdown-link{
  background: #425563;
  color: white;
  border-radius: 8px;
  display: flex;
  height: 139px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  width: 140px;
  transition: .2s all ease-out;
}
.headerdropdown-link:hover{
  background: #3d4c59;
}
.headerdropdown-icon {
  background: #ccded3;
  height: 40px;
  width: 40px;
  border-radius: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerdropdown-name {
  font-size: 15px;
  padding: 0 20px;
  text-align: center;
  font-family: 'Barlow';
  font-weight: 500;
  line-height: 21px;
  color: #dfeae3;
}
.header__search__toggle,
.header__search__close{
  display: none;
}
.header__search__input::placeholder {
  color: var(--gray-200, #d4d8db);
}

.navbar__right {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dropdown__suscripciones {
  color: var(--green-200, #dfeae3);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  margin-bottom: 20px;
  width: 100%;
}
.link-suscripciones{
  align-items: center;
  background: #425563;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 19px;
  text-decoration: none;
  width: 100%;
}

@media (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
  .hide-mobile {
    display: block;
  }

  .interconectar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;
    height: 56px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 209px;
    margin-right: -1.5em;
    cursor: pointer;
  }
  .header__btn-login{
    
    height: 56px;
    font-size: 17.09px;
    padding: 16px 24px;
  }
  .header__logo {
    width: 230px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

    /* Frame 28 */
  .header__search {
    display: none;
  }
  
  /* Frame 29 */
  .header__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 8px;
    gap: 0.6em;
    width: auto;
    height: auto;
    background: #425563;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    flex: none;
    order: 3;
    flex-grow: 0;
    /* width: 317px; */
    cursor: pointer;
  }
  
  /* Frame 30 */
  .header__account {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 7px 4px 8px;
    gap: 0.6em;
    width: auto;
    height: auto;
    background: #425563;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    flex: none;
    order: 4;
    flex-grow: 0;
    /* width: 247px; */
    cursor: pointer;
  }
  .header__account__text {
    width: 45px;
    height: 14px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #cedfd5;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .header__account__preferences {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: #cedfd5;
    border-radius: 20px;
    flex: none;
    order: 1;
    flex-grow: 0;
    transition: .2s all ease-out;
  }

  .header__account__bookmark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: #cedfd5;
    border-radius: 20px;
    flex: none;
    order: 2;
    flex-grow: 0;
    transition: .2s all ease-out;
  }
  
  /* Herramientas */
  .header__tools__text {
    width: 90px;
    height: 14px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #cedfd5;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  
  /* Buscar noticia o sugerencia */
  .header__search__input {
    outline: none;
    border: none;
    background: transparent;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    


    height: 22px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: 15.19px;
    line-height: 21px;
    color: var(--gray-200, #d4d8db);
    flex: none;
    order: 0;
    
    flex-grow: 1;
  }

  .header__search__input::placeholder {
    color: var(--gray-200, #d4d8db);
    font-family: Barlow;
    font-size: 15.19px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.266px;
    /* 140% */
  }
  .header__account__bookmark:hover,
  .header__account__preferences:hover,
  .header__tools__block:hover {
    background: #9ea7ae;
  }
  
  .navbar__right {
    gap: 30px;
  }
}

@media (min-width: 1024px) {
  
  .header__search {
    display: flex;
    padding: 8px 8px 8px 16px;
    align-items: center;
    gap: 14px;
    
    border-radius: 28px;
    background: var(--gray-700, #425563);
    order: 2;
    cursor: pointer;
    flex-grow: 1;
  }
}

@media (min-width: 1440px) {
  
  .header__account__text ,
  .header__tools__text{
    display: flex;
  }
  .dropdown__user {
    right: inherit;
  }
  .header__account,
  .header__tools{
    padding-left: 16px;
  }
}

